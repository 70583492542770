import { useRef, useState, useCallback } from "react";

const useAnimationFrame = (onFrame, duration) => {
  const [playing, setPlaying] = useState(false);
  const request = useRef();
  const startTime = useRef();
  const progress = useRef(0);

  const animate = useCallback(
    time => {
      if (!startTime.current) {
        startTime.current = time - progress.current;
      }

      progress.current = time - startTime.current;

      onFrame({
        time: progress.current,
        progress: duration && progress.current / (duration * 1000)
      });
      request.current = requestAnimationFrame(animate);
    },
    [duration, onFrame]
  );

  const play = useCallback(() => {
    setPlaying(true);
    request.current = requestAnimationFrame(animate);
  }, [animate]);

  const pause = useCallback(() => {
    request.current && cancelAnimationFrame(request.current);
    startTime.current = null;

    setPlaying(false);
  }, []);

  const setProgress = useCallback(
    percent => {
      progress.current = duration * 1000 * percent;
    },
    [duration]
  );

  return { play, pause, playing, progress: setProgress };
};

export default useAnimationFrame;
