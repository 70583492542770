import React, { memo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ArrowUpCircle, MinusCircle, HelpCircle } from "react-feather";

import "../../panel.css";
import {
  legend,
  legendBody,
  label,
  explanation,
  gradient,
  gradientCases,
  gradientDeaths
} from "./Legend.module.css";

const Legend = ({ field }) => {
  return (
    <div className={classNames(legend, "panel")}>
      <div className={legendBody}>
        <div>
          <p className={label}>Percent change from yesterday:</p>

          <p className={classNames(label, explanation)}>
            <span>Less Change</span>
            <span>Larger Increase</span>
          </p>

          <div
            className={classNames(gradient, {
              [gradientCases]: field === "cases",
              [gradientDeaths]: field === "deaths"
            })}
          >
            <MinusCircle size={15} />
            <ArrowUpCircle size={15} />
          </div>

          <p className={label}>
            <span>0%</span>
            <span>+25%</span>
          </p>
        </div>

        <Link to={location => ({ ...location, pathname: "/introduction" })}>
          <HelpCircle />
        </Link>
      </div>
    </div>
  );
};

export default memo(Legend);
