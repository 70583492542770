import {
  heightExpressionFor,
  heightPerCapitaExpressionFor,
  colorExpressionFor
} from "./shared";

const deathsFactor = 20;
const deathsPerCapitaFactor = 10 ** 7.7;

const deaths = ["feature-state", "deaths"];
const changeInDeaths = ["feature-state", "deathChange"];

export const deathsHeight = heightExpressionFor(deaths, deathsFactor);
export const deathsHeightPerCapita = heightPerCapitaExpressionFor(
  deaths,
  deathsPerCapitaFactor
);

export const deathsColor = colorExpressionFor(deaths, [
  "interpolate",
  ["linear"],
  ["case", ["==", changeInDeaths, null], 0, changeInDeaths],
  -25,
  "rgba(255,9,6,0.3)",
  0,
  "rgba(255,9,6,0.5)",
  25,
  "rgba(255,9,6,1)"
]);
