import { memo, useEffect } from "react";
import mapboxgl from "mapbox-gl";

import { useMap } from "./Mapbox";

const Controls = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    map.addControl(new mapboxgl.NavigationControl(), position);
  }, [map, position]);

  return null;
};

export default memo(Controls);
