import { useEffect } from "react";
import { useMap } from "./Mapbox";

const source = {
  source: "counties",
  sourceLayer: "county_centroid_blocks"
};

const CountyData = ({ id, ...data }) => {
  const map = useMap();

  useEffect(() => {
    return () => map.removeFeatureState({ ...source, id });
  }, [id, map]);

  useEffect(() => {
    map.setFeatureState({ ...source, id }, data);
  });

  return null;
};

export default CountyData;
