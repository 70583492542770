import "react-app-polyfill/ie11";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";

import App from "./components/App";

import "./global.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_TOKEN);
  ReactGA.pageview(window.location.pathname);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment:
      process.env.REACT_APP_CONTEXT === "production" ? "Production" : "Staging"
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
