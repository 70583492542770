const functionFor = {
  day: "Date",
  days: "Date",
  minute: "Minutes",
  minutes: "Minutes"
};

export const DAY_0 = parseDate("2020-01-21");

export function addTime(date, time, unit) {
  const newDate = new Date(Number(date));
  newDate[`set${functionFor[unit]}`](date[`get${functionFor[unit]}`]() + time);
  return newDate;
}

export function parseDate(dateString) {
  return new Date(`${dateString}T00:00:00`);
}

export function dateKey(date) {
  return date.toISOString().split("T")[0];
}

export function dayNumber(dateOrString) {
  const date =
    typeof dateOrString === "string" ? parseDate(dateOrString) : dateOrString;
  return Math.floor((date - DAY_0) / (1000 * 60 * 60 * 24));
}
