import React from "react";

import Modal from "../Modal";

const ErrorModal = () => (
  <Modal>
    <h1>Oops...something went wrong.</h1>
    <p>We're sorry—the map failed to load—and that's the best part!</p>
    <p>
      I've logged this error and will look into it. In the meantime, double
      check that your computer and browser support WebGL, which is required to
      run this map.
    </p>
    <p style={{ marginBottom: 0 }}>
      <a href="https://get.webgl.org/">Click here to test WebGL</a>
    </p>
  </Modal>
);

export default ErrorModal;
