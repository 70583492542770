import React, { memo } from "react";
import CountyData from "./CountyData";

const CountiesData = ({ data = [] }) =>
  data.map(
    ({ fips, ...countyData }) =>
      !!fips && <CountyData key={fips} id={+fips} fips={fips} {...countyData} />
  );

export default memo(CountiesData);
