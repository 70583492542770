import React, { memo } from "react";

import ChangeIndicator from "./ChangeIndicator";
import Style from "./CountyPopover.module.css";

const CountyPopover = ({
  name,
  state,
  pop_2019,
  cases,
  caseChange,
  deaths,
  deathChange
}) => (
  <section className={Style.Popover}>
    <div className={Style.Header}>
      <div style={{ flex: 1 }}>
        <h1 className={Style.Name}>{name}</h1>
        <h2 className={Style.State}>{state}</h2>
      </div>

      <p className={Style.Pop}>
        {pop_2019.toLocaleString()}
        <label className={Style.Label}>Population</label>
      </p>
    </div>

    <div className={Style.Stats}>
      <p className={Style.Stat}>
        <span>
          {(cases || 0).toLocaleString()}
          <ChangeIndicator change={caseChange} />
        </span>
        <label className={Style.Label}>Total Cases</label>
      </p>

      <p className={Style.Stat}>
        <span>
          {(deaths || 0).toLocaleString()}
          <ChangeIndicator change={deathChange} />
        </span>
        <label className={Style.Label}>Total Deaths</label>
      </p>
    </div>
  </section>
);

export default memo(CountyPopover);
