import React, { memo } from "react";
import { dataSelector, active, selected } from "./DataSelector.module.css";
import { useSpring, animated } from "react-spring";

const Selection = memo(({ value, onChange, field }) => (
  <button
    onClick={() => onChange(field)}
    className={value === field ? active : ""}
  >
    {field}
  </button>
));

const DataSelector = props => {
  const style = useSpring({
    transform:
      props.value === "cases" ? "translate3d(0%,0,0)" : "translate3d(100%,0,0)"
  });

  return (
    <div className={dataSelector}>
      <animated.div className={selected} style={style} />
      <Selection field="cases" {...props} />
      <Selection field="deaths" {...props} />
    </div>
  );
};

export default memo(DataSelector);
