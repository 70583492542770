import React from "react";
import { Link } from "react-router-dom";

import { overlay, backdrop, modal } from "./Modal.module.css";

const Modal = ({ children, to }) => (
  <div className={overlay}>
    {to && (
      <Link
        className={backdrop}
        to={location => ({ ...location, pathname: to })}
      />
    )}
    <section className={modal}>{children}</section>
  </div>
);

export default Modal;
