import React, { useState, useEffect, useCallback } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// eslint-disable-next-line import/no-webpack-loader-syntax
import DataDownloader from "workerize-loader!../../worker";
import useGALogger from "../../hooks/useGALogger";
import useQuerySettings from "../../hooks/useQuerySettings";

import Introduction from "../Introduction";

import Mapbox, { Controls } from "../Mapbox";
import CountyBarsLayer from "../CountyBarsLayer";
import CountiesData from "../CountiesData";
import HoverLayer from "../HoverLayer";
import DataPanel from "../DataPanel";
import Legend from "../Legend";
import Modal from "../Modal";

import {
  casesHeight,
  casesHeightPerCapita,
  casesColor,
  deathsHeight,
  deathsHeightPerCapita,
  deathsColor
} from "../../expressions";

const downloader = DataDownloader();

const isDefined = v => typeof v !== "undefined";
const hasLatLong = ({ lat, lng }) => isDefined(lat) && isDefined(lng);

const expressionFor = {
  cases: {
    true: { height: casesHeightPerCapita, color: casesColor },
    false: { height: casesHeight, color: casesColor }
  },
  deaths: {
    true: { height: deathsHeightPerCapita, color: deathsColor },
    false: { height: deathsHeight, color: deathsColor }
  }
};

const App = () => {
  const [{ field, perCapita, ...query }, { replaceQuery }] = useQuerySettings();

  const [day, setDay] = useState(null);
  const [usCases, setUsCases] = useState(null);
  const [countyCases, setCountyCases] = useState(null);

  useGALogger(day);

  const showIntro = !localStorage.getItem("seenIntro");

  const onMove = useCallback(
    map =>
      replaceQuery(q => ({
        ...q,
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        z: map.getZoom().toFixed(2),
        p: map.getPitch().toFixed(2),
        b: map.getBearing().toFixed(2)
      })),
    [replaceQuery]
  );

  useEffect(() => {
    downloader.addEventListener("message", ({ data }) => {
      const { type, payload } = data;

      switch (type) {
        case "usCases":
          setUsCases(payload);
          setDay(currentDay =>
            typeof currentDay !== "number" ? payload.length - 1 : currentDay
          );
          break;
        case "countyCases":
          setCountyCases(payload);
          break;
        default:
      }
    });

    downloader.getData();
  }, []);

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <Mapbox
        onMove={onMove}
        center={hasLatLong(query) ? [query.lng, query.lat] : undefined}
        zoom={query.z || 0}
        pitch={isDefined(query.p) ? query.p : 50}
        bearing={query.b || 0}
        bounds={
          hasLatLong(query)
            ? null
            : [
                [-125.0011, 24.9493],
                [-66.9326, 49.5904]
              ]
        }
        attribution="Data provided by The New York Times and US Census Bureau"
        fitBoundsOptions={{ padding: 100 }}
      >
        <Controls position="top-left" />
        <CountyBarsLayer {...expressionFor[field][perCapita]} />

        {countyCases && <CountiesData data={countyCases[day]} />}

        {usCases && <DataPanel usCases={usCases} day={day} setDay={setDay} />}

        <Legend field={field} />

        <HoverLayer />
      </Mapbox>

      <Switch>
        <Route path="/introduction">
          <Modal to="/">
            <Introduction />
          </Modal>
        </Route>
        {showIntro && <Redirect to="/introduction" />}
      </Switch>
    </div>
  );
};

export default App;
