import React, { memo } from "react";
import classNames from "classnames";

import { toggle, active } from "./Toggle.module.css";

const Toggle = ({ checked, onChange, children }) => (
  <label className={classNames(toggle, { [active]: checked })}>
    <input type="checkbox" checked={checked} onChange={onChange} />
    {children}
  </label>
);

export default memo(Toggle);
