export const population = ["get", "pop_2019"];
export const hovering = ["feature-state", "hover"];

export const heightExpressionFor = (field, factor = 1) => [
  "case",
  ["==", field, null],
  0,
  ["*", field, factor]
];

export const heightPerCapitaExpressionFor = (field, factor = 1) => [
  "case",
  ["==", field, null],
  0,
  ["*", ["/", field, population], factor]
];

export const colorExpressionFor = (field, scheme) => [
  "case",
  ["==", hovering, true],
  "#5CECFF",
  ["==", field, null],
  "#222",
  ["==", field, 0],
  "rgba(97, 66, 67, 0.3)",
  scheme
];
