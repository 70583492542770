import React, { memo } from "react";
import { ArrowUpCircle, ArrowDownCircle, MinusCircle } from "react-feather";

const ChangeIndicator = ({ change }) => {
  const baseStyle = { marginLeft: 5 };

  if (change > 12.5) {
    return (
      <ArrowUpCircle
        style={{ ...baseStyle, color: "hsla(333, 92%, 48%, 1)" }}
      />
    );
  } else if (change < -12.5) {
    return <ArrowDownCircle style={{ ...baseStyle, color: "#8949cc" }} />;
  } else {
    return <MinusCircle style={{ ...baseStyle, color: "#8949cc" }} />;
  }
};

export default memo(ChangeIndicator);
