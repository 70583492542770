import {
  heightExpressionFor,
  heightPerCapitaExpressionFor,
  colorExpressionFor
} from "./shared";

const casesFactor = 15;
const casesPerCapitaFactor = 10 ** 7.3;

const cases = ["feature-state", "cases"];
const changeInCases = ["feature-state", "caseChange"];

export const casesHeight = heightExpressionFor(cases, casesFactor);
export const casesHeightPerCapita = heightPerCapitaExpressionFor(
  cases,
  casesPerCapitaFactor
);

export const casesColor = colorExpressionFor(cases, [
  "interpolate",
  ["linear"],
  ["case", ["==", changeInCases, null], 0, changeInCases],
  0,
  "#8949cc",
  25,
  "#EB0A6F"
]);
