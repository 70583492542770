import React, { memo } from "react";
import { AxisBottom } from "@vx/axis";
import { Line } from "@vx/shape";
import { Group } from "@vx/group";

const tickColor = "#fff";

const DateAxis = ({ formatMonth, ...props }) => (
  <AxisBottom {...props}>
    {axis => (
      <g opacity="0.5">
        <Line
          from={axis.axisFromPoint}
          to={axis.axisToPoint}
          stroke={tickColor}
        />
        {axis.ticks.map((tick, i) => (
          <Group key={`vx-tick-${tick.value}-${i}`}>
            {(i === 0 || (+tick.formattedValue + 5) % 6 === 0) &&
              tick.formattedValue !== 31 && (
                <React.Fragment>
                  <Line from={tick.from} to={tick.to} stroke={tickColor} />
                  <text
                    className="noSelect"
                    fill={tickColor}
                    fontSize={10}
                    fontWeight="bold"
                    textAnchor="middle"
                    transform={`translate(${tick.to.x}, ${tick.to.y +
                      5 +
                      axis.tickLength})`}
                  >
                    {tick.formattedValue}
                  </text>
                </React.Fragment>
              )}
            {(i === 0 || tick.formattedValue === 1) && (
              <text
                className="noSelect"
                fill={tickColor}
                fontSize={10}
                fontWeight="bold"
                transform={`translate(${tick.to.x - 2}, ${tick.to.y +
                  20 +
                  axis.tickLength})`}
              >
                {formatMonth(tick.value)}
              </text>
            )}
          </Group>
        ))}
      </g>
    )}
  </AxisBottom>
);

export default memo(DateAxis);
