import { useEffect, memo } from "react";
import { useMap } from "./Mapbox";

const CountyBarsLayer = ({ height, color }) => {
  const map = useMap();

  useEffect(() => {
    map.addLayer(
      {
        id: "counties",
        type: "fill-extrusion",
        source: {
          type: "vector",
          url: process.env.REACT_APP_COUNTY_BARS_SOURCE_URL
        },
        "source-layer": "county_centroid_blocks",
        paint: {
          "fill-extrusion-opacity": 0.75,
          "fill-extrusion-color": color,
          "fill-extrusion-height": height
        }
      },
      "settlement-subdivision-label"
    );

    return () => map.removeLayer("counties");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    map.setPaintProperty("counties", "fill-extrusion-height", height);
    map.setPaintProperty("counties", "fill-extrusion-color", color);
  }, [map, height, color]);

  return null;
};

export default memo(CountyBarsLayer);
