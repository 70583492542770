import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { X } from "react-feather";

const Introduction = () => {
  useEffect(() => {
    localStorage.setItem("seenIntro", true);
  }, []);

  return (
    <section>
      <div style={{ float: "right" }}>
        <Link
          to={location => ({ ...location, pathname: "/" })}
          className="icon-btn"
        >
          <X />
        </Link>
      </div>

      <h1 style={{ fontSize: "2em" }}>Welcome</h1>

      <p>
        This 3D interactive map plots the current and historical COVID-19 cases{" "}
        <i>per-county</i>. You'll see a colored bar for each US county, where
        the height represents the number of cumulative <i>reported</i> confirmed
        cases. The colors indicate the percentage change from the day prior.
      </p>

      <h2>How to use this map</h2>

      <p>
        This map is fully interactable—so move around, zoom in for more detail,
        and also change the <b>3D</b> perspective. The navigation works as
        follows:
      </p>
      <ul>
        <li>
          Left-click and drag to <b>move/pan</b> the map
        </li>
        <li>
          Scroll to <b>zoom</b> in on your cursor
        </li>
        <li>
          Right-click and drag to adjust the camera <b>tilt and bearing</b>
        </li>
      </ul>

      <p>
        Furthermore, there are data-related features to understand the data
        better:
      </p>
      <ul>
        <li>
          Drag the blue cursor on the <b>history graph</b> to move "back in
          time" and see the cases since January 21st, 2020.
        </li>
        <li>
          Toggle <b>per capita</b> mode to view the cases as a proportion of the
          county's population.
        </li>
        <li>
          <b>Hover</b> over a county bar to see case and death counts.
        </li>
      </ul>

      <p style={{ margin: "2rem 0" }}>
        <Link to={location => ({ ...location, pathname: "/" })} className="btn">
          Start Exploring the Data
        </Link>
      </p>

      <section className="muted">
        <p>
          Cases and deaths{" "}
          <a href="https://github.com/nytimes/covid-19-data">data</a> from The
          New York Times, based on reports from state and local health agencies.
        </p>

        <p>
          Special thanks and inspiration goes to them for providing accurate and
          well-researched data. Please visit their{" "}
          <a href="https://www.nytimes.com/interactive/2020/us/coronavirus-us-cases.html">
            tracking page
          </a>{" "}
          for coverage and news.
        </p>

        <p>
          Population data and county boundaries provided by the US Census{" "}
          <a href="https://www.census.gov/geographies/reference-files/time-series/geo/gazetteer-files.html">
            databank
          </a>
          .
        </p>

        <a href="https://developer.nytimes.com">
          <img
            src="https://developer.nytimes.com/files/poweredby_nytimes_150c.png?v=1568441068237"
            alt="NYT API Logo"
          />
        </a>
      </section>
    </section>
  );
};

export default Introduction;
